import './styles/index.scss';
import 'bootstrap.native/dist/bootstrap-native-v4';




//const classChildren = x => document.getElementsByClassName('navbar-main')[0];
//
//classChildren('toggle').addEventListener('click', () => {
//  classChildren('collapse').classList.toggle('collapse');
//}, false);


// document.addEventListener("DOMContentLoaded", () => {
//
//     const pluginsTriggerElement = document.getElementById('plugins-trigger');
//     const pluginsElement = document.getElementById('plugins');
//
//     const pluginsVisibleClass = "splash-overview-plugins__list--visible";
//
//     pluginsTriggerElement.onclick = () => {
//         pluginsElement.classList.toggle(pluginsVisibleClass);
//     }
// });
